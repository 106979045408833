import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopNavBar from "./TopNavBar";
import SideList from "./SideList";
import Footer from "./Footer";

const Layout = () => {
  const [open, setOpen] = useState(false);
  const [dark, setDark] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      {/* Navbar */}
      <TopNavBar open={open} setOpen={setOpen} dark={dark} setDark={setDark} />
      {/* Sidebar and Content */}
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <SideList
          open={open}
          setOpen={setOpen}
          isMobile={isMobile}
          sx={{
            position: "fixed",
            left: 0,
            top: 56,
            height: "calc(100vh - 56px)",
            width: isMobile ? "0px" : "200px",
            overflowY: "auto", // Allow scrolling within SideList itself
            backgroundColor: "background.paper",
            zIndex: 1100,
            transition: "width 0.3s ease-in-out",
          }}
        />
        {/* Main Content Area */}
        <Box
          component="main"
          onClick={() => open && setOpen(false)} // Close SideList only if it's open
          sx={{
            flexGrow: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            minHeight: "calc(100vh - 56px)",
            marginLeft: open && !isMobile ? "200px" : !isMobile ? "64px" : 0,
            pt: { xs: 2, md: 3 },
            px: { xs: 2, md: 3 },
            mt: 4,
            transition: "margin 0.3s ease-out",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
