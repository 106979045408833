import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Drawer as MuiDrawer, SwipeableDrawer, Badge } from "@mui/material";
import { ListItemIcon, ListItemText, Collapse, ListItem } from "@mui/material";
import { List, ListItemButton, Tooltip, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Folder, Quiz, School, Person, MenuBook } from "@mui/icons-material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { nav } from "./navigation";
import { Box } from "@mui/system";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
// }));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  // width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`, // Mini width when closed
  // flexShrink: 0,
  // whiteSpace: "nowrap",
  // boxSizing: "border-box",
  // display: "flex",
  // flexDirection: "column",
  position: "fixed",
  top: "56px",
  left: 0,
  zIndex: 1200,
  height: "calc(100vh - 56px)",
  overflowY: "auto",
  transition: "width 0.3s ease-out",

  "& .MuiDrawer-paper": {
    // borderColor: "rgb(255, 165, 0)",
    position: "fixed",
    top: "56px", // Align with the new navbar height
    left: 0,
    height: "calc(100vh - 56px)", // Take full height below navbar
    width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`, // Mini width
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    zIndex: 100, // Ensure it's above other content
    boxShadow: theme.shadows[3], // Optional for a subtle effect
    overflowY: "auto", // Allows the sidebar content to scroll independently
    // alignItems: "flex-start",
    // flexGrow: 1,
    // overflowY: "auto",
    // visibility: "visible", // Keep icons always visible
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
}));

const SideList = ({ open, setOpen, isMobile }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userFlagQty, setUserFlagQty] = useState(0);
  const [userPriorityQty, setUserPriorityQty] = useState(0);
  const [userDiscussQty, setUserDiscussQty] = useState(0);
  const [menuState, setMenuState] = useState({});
  const [activeTooltip, setActiveTooltip] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await axiosPrivate.get(`/userBadges?&user=${currentUser.id}`);
        console.log("Response:", JSON.stringify(response.data));
        setUserFlagQty(response.data[0].flagQty);
        setUserPriorityQty(response.data[0].priorityQty);
        setUserDiscussQty(response.data[0].discussQty);
      } catch (error) {
        if (error.name === "CanceledError") {
          console.log("Request was canceled.");
          return;
        }
        if (error.response?.status === 401 || error.response?.status === 403) {
          // Redirect to login for unauthorized errors
          navigate("/login", { state: { from: location.pathname }, replace: true });
        } else {
          console.error("Error retrieving data:", error);
        }
        navigate("/login", { state: { from: location.pathname }, replace: true });
      } finally {
        setLoading(false);
      }
    };
    if (currentUser.id) {
      getUserData();
    }
  }, [axiosPrivate, navigate, location.pathname, currentUser.id]);

  const toggleMenu = (menuName, forceOpen = false) => {
    setMenuState((prev) => {
      const newState = Object.keys(prev).reduce(
        (acc, key) => ({
          ...acc,
          [key]: false, // Collapse all menus
        }),
        {}
      );
      return {
        ...newState,
        [menuName]: forceOpen || !prev[menuName],
      };
    });
  };

  const handleClick = (menuName) => {
    setActiveTooltip(null); // Force tooltip to close
    setOpen(true); // Expand the SideList
    toggleMenu(menuName, true); // Force open the clicked menu
  };

  const handleOptionClick = (subItemPath) => {
    navigate(subItemPath); // Navigate to the selected option
    toggleMenu(null); // Collapse all menus
    setOpen(false); // Close the SideList
  };

  const topLevelMenus = [
    { name: "List", icon: <Folder /> },
    { name: "Study", icon: <School /> },
    { name: "Quiz", icon: <Quiz /> },
    { name: "Resources", icon: <MenuBook /> },
    { name: "My Content", icon: <Person /> },
  ];

  const drawerContent = (
    <>
      {/* <DrawerHeader>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider /> */}
      <List>
        {topLevelMenus.map((menu) => (
          <React.Fragment key={menu.name}>
            <ListItem disablePadding>
              {!open ? (
                <Tooltip
                  title={<Typography sx={{ fontSize: "1rem" }}>{menu.name}</Typography>}
                  placement="right"
                  arrow
                  open={activeTooltip === menu.name}
                >
                  <ListItemButton
                    onMouseEnter={() => setActiveTooltip(menu.name)} // Show tooltip
                    onMouseLeave={() => setActiveTooltip(null)} // Hide tooltip
                    onClick={() => handleClick(menu.name)} // Handle click
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>{menu.icon}</ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              ) : (
                <ListItemButton onClick={() => toggleMenu(menu.name)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.name} />
                  {menuState[menu.name] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              )}
            </ListItem>
            <Collapse in={menuState[menu.name] && open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {nav
                  .filter((item) => item.parent === menu.name)
                  .map((subItem) => (
                    <ListItem key={subItem.path} disablePadding>
                      <ListItemButton sx={{ pl: 4 }} onClick={() => handleOptionClick(subItem.path)}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 3.5 }}>
                          <ListItemText primary={subItem.name} />
                          {["Flag", "Priority", "Discuss"].includes(subItem.name) && (
                            <Badge
                              badgeContent={
                                subItem.name === "Flag"
                                  ? userFlagQty > 0
                                    ? userFlagQty
                                    : 0
                                  : subItem.name === "Priority"
                                  ? userPriorityQty > 0
                                    ? userPriorityQty
                                    : 0
                                  : subItem.name === "Discuss"
                                  ? userDiscussQty > 0
                                    ? userDiscussQty
                                    : 0
                                  : 0
                              }
                              color="primary"
                              invisible={
                                (subItem.name === "Flag" && userFlagQty <= 0) ||
                                (subItem.name === "Priority" && userPriorityQty <= 0) ||
                                (subItem.name === "Discuss" && userDiscussQty <= 0)
                              }
                              sx={{ ".MuiBadge-badge": { transform: "translate(0, -60%)" } }} // Adjusts badge positioning
                            />
                          )}
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </>
  );

  if (loading) {
    return (
      <Typography variant="body1" sx={{ textAlign: "center", mt: 5 }}>
        Loading...
      </Typography>
    );
  }

  return isMobile ? (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      PaperProps={{
        sx: {
          width: drawerWidth,
          mt: "56px",
          height: "calc(100vh - 56px)",
          overflowY: "auto",
        },
      }}
    >
      {drawerContent}
    </SwipeableDrawer>
  ) : (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        position: "fixed",
        top: "56px", // Start below the TopNavBar
        left: 0,
        height: "calc(100vh - 56px)", // Full height under TopNav
        width: open ? "200px" : "64px", // Adjust width dynamically
        transition: "width 0.3s ease-in-out",
        zIndex: 1100, // Keep it above content but below the TopNavBar
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default SideList;
