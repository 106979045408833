// components/HanziWriter.js
import React, { useState, useEffect, useRef } from "react";
import HanziWriter from "hanzi-writer";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

const HanziCharacter = ({ character, width = 100, height = 100, fontSize = "72px" }) => {
  const writerRef = useRef(null);
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    // Special case: Directly render certain characters inside Typography
    const fallbackCharacters = ["疋", "𤴔", "⺷"]; // Add more characters as needed
    if (fallbackCharacters.includes(character)) {
      setError(true);
      return;
    }

    if (writerRef.current) {
      writerRef.current.innerHTML = ""; // Clear any previous instances
      const strokeColor = theme.palette.text.primary;

      // Check if the character data exists before attempting to create the writer
      HanziWriter.loadCharacterData(character)
        .then(() => {
          HanziWriter.create(writerRef.current, character, {
            width,
            height,
            padding: 5,
            strokeAnimationSpeed: 0, // Adjust for faster/slower stroke animation
            delayBetweenStrokes: 0, // Delay between strokes (in ms)
            radicalColor: strokeColor, // Color for radicals
            strokeColor: strokeColor, // Stroke color
            showOutline: false, // Show character outline (set to true if animating)
            showCharacter: true, // Hide static character
          });
          setError(false); // Reset error if successful
        })
        .catch(() => {
          console.error(`Failed to load character data for: ${character}`);
          setError(true); // Set error if character data is not available
        });
      // writer.loopCharacterAnimation(); // Loop the animation continuously
    }
  }, [character, width, height, theme.palette.text.primary]);

  return error ? (
    <Typography
      variant="h5"
      sx={{
        fontSize, // Dynamically set font size from prop
        fontWeight: 300,
        fontFamily: '"Hiragino Mincho ProN", "Songti SC", "Noto Serif SC", serif',
        lineHeight: 1,
        pb: 2,
      }}
    >
      {character}
    </Typography>
  ) : (
    <div ref={writerRef} style={{ margin: "-8px" }} />
  );
};

export default HanziCharacter;
