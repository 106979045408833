import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Box, Button, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { Flag, FlashOn, Comment, Cancel, PriorityHigh, Undo } from "@mui/icons-material";
import HanziCharacter from "../../utils/HanziWriter";

const Quiz = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizMeaning, setQuizMeaning] = useState("");
  const [quizScore, setQuizScore] = useState(0);
  const [partsData, setPartsData] = useState([]);
  const [subPartsData, setSubPartsData] = useState([]);
  const [usesData, setUsesData] = useState([]);
  const [phrasesData, setPhrasesData] = useState([]);
  const [paronymData, setParonymData] = useState([]);
  const [renderKey, setRenderKey] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [flippedOnce, setFlippedOnce] = useState(false);
  const [pinyinInput, setPinyinInput] = useState("");
  const [pinyinLetterMatch, setPinyinLetterMatch] = useState(false);
  const [pinyinMatch, setPinyinMatch] = useState(false);
  const pinyinTextFieldRef = useRef(null); // Allow focus at each render
  const [flagId, setFlagId] = useState(0);
  const [flagFlag, setFlagFlag] = useState(0); // To toggle flagging mode
  const [flagging, setFlagging] = useState(false); // To toggle flagging mode
  const [flagNote, setFlagNote] = useState("");
  const flagTextFieldRef = useRef(null); // Allow focus after flagging
  const [discussId, setDiscussId] = useState(0);
  const [discussNotify, setDiscussNotify] = useState(0);
  const [discussing, setDiscussing] = useState(false); // Toggle discussing mode
  const [discussNote, setDiscussNote] = useState("");
  const discussTextFieldRef = useRef(null); // Allow focus after discussging
  const [priorityId, setPriorityId] = useState(0);
  const [enableAnswerBoxes, setEnableAnswerBoxes] = useState(false);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const audioPlayed = useRef(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [incorrectSoundAttempts, setIncorrectSoundAttempts] = useState(0);
  const [selectedCorrectSoundAnswer, setSelectedCorrectSoundAnswer] = useState(null);
  const [incorrectSoundAnswers, setIncorrectSoundAnswers] = useState([]);
  const [allSoundAnswerOptions, setAllSoundAnswerOptions] = useState([]);
  const [shuffledSoundAnswers, setShuffledSoundAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(currentUser.timer || 20);
  const [soundScore, setSoundScore] = useState(6);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { quizCat: rawQuizCat, quizType } = location.state || {};
  const quizCat = rawQuizCat?.endsWith(".0") ? rawQuizCat.replace(/\.0$/, "") : rawQuizCat;
  const clickMode = currentUser.clickMode;
  // console.log("clickMode: ", clickMode);
  // console.log("currentUser: ", currentUser);

  useEffect(() => {
    // Countdown timer logic
    if (currentUser.timer === 0) return; // Skip timer logic if timer is disabled
    if (remainingTime === 0) return; // If the timer is at 0, prevent creating a new interval
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) return prevTime - 1;
        clearInterval(timer); // Stop the timer when it reaches 0
        return 0;
      });
    }, 1000);
    return () => clearInterval(timer); // Cleanup on unmount
  }, [remainingTime, currentUser.timer]);

  const restartTimer = () => {
    setRemainingTime(currentUser.timer);
  };
  // Function to format time with leading zero
  const formatTime = (time) => (time < 10 ? `0${time}` : time);
  // Determine timer color based on remaining time
  const getTimerColor = () => {
    if (remainingTime < 5) return "red";
    if (remainingTime < 10) return "orange";
    return "inherit"; // Default color
  };

  const soundAnswerCharacter = useMemo(() => {
    if (quizQuestions.length === 0) return null;
    if (quizQuestions[0].hanzi_type === 1) {
      return quizQuestions[0].hanzi_character.charAt(0); // Use first character
    } else if (quizQuestions[0].hanzi_character.length > 1) {
      const characters = quizQuestions[0].hanzi_character.split("");
      const randomIndex = Math.floor(Math.random() * characters.length);
      return characters[randomIndex]; // Use a random character
    }
    return quizQuestions[0].hanzi_character; // Use the full character if single
  }, [quizQuestions]);

  const pinyinWithHighlight = useMemo(() => {
    // console.log("206 soundAnswerCharacter: ", soundAnswerCharacter);
    if (quizQuestions.length === 0) return [];
    if (quizQuestions[0].hanzi_type === 1 || quizQuestions[0].hanzi_character.length === 1) {
      return [{ text: quizQuestions[0].hanzi_pinyin, bold: false }]; // No highlight needed
    } else {
      const characters = quizQuestions[0].hanzi_character.split("");
      const pinyins = quizQuestions[0].hanzi_pinyin.split(" ");
      const index = characters.indexOf(soundAnswerCharacter); // Find the index of the soundAnswerCharacter
      return pinyins.map((pinyin, i) => ({
        text: pinyin,
        bold: i === index, // Highlight the pinyin if its index matches the character
      }));
    }
  }, [quizQuestions, soundAnswerCharacter]);

  const handleNewSoundQuestion = useCallback(() => {
    if (
      quizType !== "Sound" ||
      quizQuestions.length === 0 ||
      // audioPlayed.current ||
      allSoundAnswerOptions.length === 0
    ) {
      return; // Exit if conditions are not met
    }
    console.log("handleNewSoundQuestion useCallback: ", quizQuestions[0]?.hanzi_character);
    // audioPlayed.current = true;
    // if (quizCat !== "radMini") {
    //   handlePlayAudio(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
    // } else {
    //   handlePlayAudio(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
    // }
    // Find the corresponding pinyin
    const correctPinyin = (() => {
      if (quizQuestions[0].hanzi_type === 1 || quizQuestions[0].hanzi_character.length === 1) {
        return quizQuestions[0].hanzi_pinyin; // Use the full pinyin
      } else {
        const characters = quizQuestions[0].hanzi_character.split("");
        const pinyins = quizQuestions[0].hanzi_pinyin.split(" ");
        const index = characters.indexOf(soundAnswerCharacter); // Find the index of the character answer
        return pinyins[index] || ""; // Use same index for pinyin as character, empty string if not found
      }
    })();

    const soundIncorrectList = Array.from(
      new Set(
        allSoundAnswerOptions
          .filter((q) => {
            // Exclude quizQuestions that include the soundAnswerCharacter in their hanzi_character string
            return !q.hanzi_character.includes(soundAnswerCharacter) && q.hanzi_pinyin !== correctPinyin;
          })
          .flatMap((q) => {
            const characters =
              q.hanzi_type === 1
                ? [q.hanzi_character.charAt(0)] // Single character
                : q.hanzi_character.length > 1
                ? q.hanzi_character.split("") // Split into individual characters
                : [q.hanzi_character]; // Treat as a single character if length <= 1
            // Return characters excluding any that match the soundAnswerCharacter
            return characters.filter((char) => char !== soundAnswerCharacter);
          })
      )
    ).slice(0, 17); // Limit to 17 alternate answers
    console.log("160 correctPinyin:", correctPinyin);
    console.log("Incorrect Answers:", soundIncorrectList);
    const totalBoxes = soundIncorrectList.length >= 17 ? 18 : soundIncorrectList.length >= 11 ? 12 : 6;
    const tempSoundList = soundIncorrectList.slice(0, totalBoxes - 1);
    const answers = [...tempSoundList, soundAnswerCharacter].sort(() => Math.random() - 0.5);
    console.log("answers.length:", answers.length, "quizType:", quizType);
    // setShuffledSoundAnswers(
    //   answers.map((char) => ({
    //     character: char,
    //     pinyin: char === soundAnswerCharacter ? correctPinyin : "",
    //   }))
    // );
    // On 3/25/25, I added the hanzi_type to the shuffledSoundAnswers
    setShuffledSoundAnswers(
      answers.map((char) => {
        // Default to 1 if not found
        const matched = allSoundAnswerOptions.find((q) => q.hanzi_character.includes(char));

        return {
          character: char,
          pinyin: char === soundAnswerCharacter ? correctPinyin : "",
          type: matched?.hanzi_type ?? 1,
        };
      })
    );
  }, [quizQuestions, quizType, soundAnswerCharacter, allSoundAnswerOptions]);

  useEffect(() => {
    const getData = async () => {
      // This runs only on the first render
      try {
        console.log("quizCat:", quizCat, "quizType:", quizType);
        setPinyinInput("");
        const questionsResponse = await axiosPrivate.get(
          `/quizQuestions?user=${currentUser.id}&cat=${quizCat}&type=${quizType}`
        );
        // console.log("179 questionsResponse", JSON.stringify(questionsResponse.data, null, 2));
        // If no more questions, navigate back
        if (questionsResponse.data.length === 0) {
          console.log("No more questions available. Navigating back...");
          if (quizCat.startsWith("Duo")) {
            // Extract the section number (the single digit before ":")
            const sectionMatch = quizCat.match(/^Duo(\d+):/);
            const section = sectionMatch ? sectionMatch[1] : null;
            if (section) {
              navigate("/quiz/duolingo", { state: { section } });
              return;
            }
          }
          if (quizCat.startsWith("Dom")) {
            const courseMatch = quizCat.match(/^Dom(\d+):/);
            const course = courseMatch ? courseMatch[1] : null;
            if (course) {
              console.log("Navigating to Dom course:", course);
              navigate("/quiz/domino", { state: { course } });
              return;
            }
          }
          navigate(-1);
          return;
        }
        console.log(`59 New Hanzi ID: ${questionsResponse.data[0].hanzi_id}, Char: ${questionsResponse.data[0].hanzi_character},
          Priority_id: ${questionsResponse.data[0].priority_id}, Flag_flag: ${questionsResponse.data[0].flag_flag}, 
          Flag_note: ${questionsResponse.data[0].flag_note}, Discuss_id: ${questionsResponse.data[0].discuss_id},`);

        // console.log("QuestionData: ", JSON.stringify(questionsResponse.data[0]));
        setQuizQuestions(questionsResponse.data);
        const partsPromises = Array.from({ length: 10 }, (_, i) => {
          const partId = questionsResponse.data[0][`hanzi_part${i}`];
          return partId ? axiosPrivate.get(`/questParts?hanzi=${partId}`) : Promise.resolve(null);
        });

        const partsResults = await Promise.all(partsPromises);
        const parts = partsResults.map((result) => result?.data?.[0]).filter(Boolean);
        setPartsData(parts);
        // Fetch subparts for all parts
        const subPartsPromises = parts.map((part, index) =>
          Promise.all(
            Array.from({ length: 10 }, (_, i) => {
              const subPartId = part[`hanzi_part${i}`];
              return subPartId ? axiosPrivate.get(`/questParts?hanzi=${subPartId}`) : Promise.resolve(null);
            })
          )
        );
        const subPartsResults = await Promise.all(subPartsPromises);
        const subParts = subPartsResults.map((results) => results.map((result) => result?.data?.[0]).filter(Boolean));
        // Update state with subparts data
        const subPartsDataMap = {};
        subParts.forEach((subPartArray, index) => {
          subPartsDataMap[index] = subPartArray;
        });
        setSubPartsData(subPartsDataMap);

        // Fetch uses and phrases
        const usesResponse = await axiosPrivate.get(
          `/questUses?hanzi=${questionsResponse.data[0]["hanzi_id"]}&type=${questionsResponse.data[0]["hanzi_type"]}character=${questionsResponse.data[0]["hanzi_character"]}`
        );
        // console.log("usesResponse.data", JSON.stringify(usesResponse.data));
        setUsesData(usesResponse.data);
        const phrasesResponse = await axiosPrivate.get(
          `/questPhrases?character=${questionsResponse.data[0]["hanzi_character"]}&id=${questionsResponse.data[0]["hanzi_id"]}`
        );
        // console.log("phrasesResponse.data", JSON.stringify(phrasesResponse.data));
        setPhrasesData(phrasesResponse.data);
        if (quizType === "Sound" && !audioPlayed.current && questionsResponse.data[0]?.hanzi_character) {
          console.log("Audio 232: ", questionsResponse.data[0]?.hanzi_character);
          audioPlayed.current = true;
          if (quizCat !== "radMini") {
            handlePlayAudio(questionsResponse.data[0]?.hanzi_character, questionsResponse.data[0]?.hanzi_type);
          } else {
            handlePlayAudio(questionsResponse.data[0]?.hanzi_char_full, questionsResponse.data[0]?.hanzi_type);
          }
        }
        quizCat === "Speechmeasure"
          ? setQuizMeaning(questionsResponse.data[0].hanzi_measure)
          : setQuizMeaning(questionsResponse.data[0].hanzi_meaning);
        setAllSoundAnswerOptions(questionsResponse.data);
        if (questionsResponse.data[0].priority_id > 0) {
          setPriorityId(questionsResponse.data[0].priority_id);
        }
        if (questionsResponse.data[0].flag_id > 0) {
          setFlagId(questionsResponse.data[0].flag_id);
          setFlagNote(questionsResponse.data[0].flag_note);
          setFlagFlag(questionsResponse.data[0].flag_flag);
        }
        if (questionsResponse.data[0].discuss_id > 0) {
          setDiscussId(questionsResponse.data[0].discus_id);
          setDiscussNote(questionsResponse.data[0].discus_note);
          setDiscussNotify(questionsResponse.data[0].discus_notify);
        }
        const scoreResponse = await axiosPrivate.get(
          `/quizScore?user=${currentUser.id}&cat=${quizCat}&type=${quizType}`
        );
        // console.log("scoreResponse", scoreResponse);
        setQuizScore(scoreResponse.data[0]);
        if (questionsResponse.data[0].hanzi_type < 3) {
          const ptext = questionsResponse.data[0].hanzi_ptext;
          const letters = ptext.replace(/\d+$/, ""); // Removes any trailing digit
          const id = questionsResponse.data[0].hanzi_id;
          console.log("questParonyms letters:", letters);
          const paronymResponse = await axiosPrivate.get(`/questParonyms?letters=${letters}&id=${id}`);
          // console.log("paronymResponse.data", JSON.stringify(paronymResponse.data));
          setParonymData(paronymResponse.data);
        }
      } catch (error) {
        if (error.name === "CanceledError") {
          console.log("Request was canceled.");
          return;
        }
        if (error.response?.status === 401 || error.response?.status === 403) {
          navigate("/login", { state: { from: location.pathname }, replace: true });
        } else {
          console.error("Error retrieving data:", error);
          navigate("/login", { state: { from: location.pathname }, replace: true });
        }
      } finally {
        setLoading(false);
        if (quizType !== "Sound" && clickMode === 0) {
          setTimeout(() => {
            pinyinTextFieldRef.current.focus();
          }, 0); // Delay to ensure DOM is updated
        }
      }
    };
    getData();
    // WARNING!!  I do NOT want this to rerun each time the user answers a question, so be cautious with dependencies
    // The line below prevents the linter from complaining about the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosPrivate, navigate, location.pathname, currentUser.id, quizType, quizCat, clickMode]);

  useEffect(() => {
    // Trigger handleNewSoundQuestion when quizQuestions change
    if (quizType === "Sound" && allSoundAnswerOptions.length > 0) {
      console.log("useEffect Triggering handleNewSoundQuestion for ", quizQuestions[0]?.hanzi_character);
      handleNewSoundQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizQuestions, quizType, handleNewSoundQuestion]);

  const handleSoundAnswer = (answer, type) => {
    handlePlayAudio(answer, type);
    console.log("handleSoundAnswer answer:", answer + " type:", type);
    if (soundAnswerCharacter === answer) {
      // Correct answer logic
      console.log("Correct answer selected!", answer);
      setSelectedCorrectSoundAnswer(answer);
      if (quizCat !== "radMini") {
        handleFlip(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
      } else {
        handleFlip(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
      }
      setTimeout(() => {
        handleAnswer(soundScore);
      }, 2000);
      return;
    }
    // Incorrect answer logic
    console.log("Incorrect answer selected!", answer);
    setIncorrectSoundAnswers((prev) => [...prev, answer]);
    setIncorrectSoundAttempts((prev) => {
      const newAttempts = prev + 1;
      const newSoundScore = 6 - newAttempts * 2; // 6, 4, or 2 based on attempts
      console.log("Updated incorrectSoundAttempts:", newAttempts);
      console.log("New soundScore:", newSoundScore);
      if (!flippedOnce) {
        setSoundScore(newSoundScore);
      }
      return newAttempts;
    });
    if (incorrectSoundAttempts === 2) {
      if (quizCat !== "radMini") {
        handleFlip(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
      } else {
        handleFlip(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
      }
      // Next four lines show correct answer then auto-flip in 3 seconds
      // setSelectedCorrectSoundAnswer(quizQuestions[0]?.hanzi_character);
      // setTimeout(() => {
      //   handleAnswer(2); // Final score after three incorrect attempts
      // }, 3000);
    }
  };

  const renderParts = (data) => {
    // console.log("renderRow data:", data);
    if (!data) return null;
    const { hanzi_character, hanzi_pinyin, hanzi_meaning, hanzi_type } = data;
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography variant="body1" sx={{ fontSize: "22px", fontWeight: 300, lineHeight: 1.1 }}>
          {hanzi_character.replace(/\s+/g, "")}
        </Typography>
        <Typography
          variant="body1"
          onClick={audioPlaying ? null : () => handlePlayAudio(hanzi_character, hanzi_type)}
          sx={{ color: "primary.main", fontFamily: "Spectral", cursor: "pointer", lineHeight: 1.2 }}
        >
          {hanzi_pinyin}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: 1.2 }}>
          {hanzi_meaning}
        </Typography>
      </Box>
    );
  };

  const renderSubParts = (data) => {
    // console.log("renderRow data:", data);
    if (!data) return null;
    const { hanzi_character, hanzi_pinyin, hanzi_meaning, hanzi_type } = data;
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px", ml: 2 }}>
        <Typography variant="body1" sx={{ fontSize: "22px", fontWeight: 300, lineHeight: 1.2 }}>
          {hanzi_character.replace(/\s+/g, "")}
        </Typography>
        <Typography
          variant="body1"
          onClick={audioPlaying ? null : () => handlePlayAudio(hanzi_character, hanzi_type)}
          sx={{ color: "primary.main", fontFamily: "Spectral", cursor: "pointer", lineHeight: 1.2 }}
        >
          {hanzi_pinyin}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: 1.2 }}>
          {hanzi_meaning}
        </Typography>
      </Box>
    );
  };

  const handleFlip = (text, type) => {
    console.log("handleFlip");
    if (!flippedOnce) {
      setFlipped((prev) => {
        const newState = !prev;
        // console.log("New flipped state:", newState);
        return newState;
      });
      currentUser.timer > 0 && restartTimer(); // Restart timer if it's enabled
      if (quizType !== "Sound") {
        setTimeout(() => {
          // console.log("Audio 357: ", quizQuestions[0]?.hanzi_character);
          handlePlayAudio(text, type);
        }, 500); // Delay in milliseconds
      } else {
        if (!selectedCorrectSoundAnswer) {
          setSoundScore(0); // No points if you flip before selecting the correct answer
        }
      }
      setFlippedOnce(true); // Mark as flipped once
      if (quizType !== "Sound" && (clickMode === false || clickMode === 0)) {
        setTimeout(() => {
          pinyinTextFieldRef.current.focus();
        }, 0); // Delay to ensure DOM is updated
      } else if (clickMode === 1 || clickMode === true) {
        console.log("400 handleFlip clickMode:", clickMode);
        setEnableAnswerBoxes(true);
      }
    }
  };

  const handlePlayAudio = async (text, type) => {
    if (!text) return alert("Unable to play audio because no character found.");
    const spokenCharacter = type === 1 ? text.charAt(0) : text;
    setAudioPlaying(true);
    // console.log("Polly Text:", spokenCharacter);
    try {
      const response = await axiosPrivate.post("/polly", { text: spokenCharacter }, { responseType: "blob" });
      // console.log("Polly response: " + response);
      const audioUrl = URL.createObjectURL(new Blob([response.data], { type: "audio/mpeg" }));
      // console.log("audioURL", audioUrl);
      const audio = new Audio(audioUrl);
      audio.onended = () => setAudioPlaying(false);
      await audio.play();
    } catch (error) {
      console.error("Error generating audio:", error);
      alert("Failed to generate audio.");
      setAudioPlaying(false);
    }
  };

  const handlePinyinInput = (event) => {
    let inputValue = event.target.value;
    const normalizeLettersAndNumbers = (str) => str.replace(/\s+/g, "").trim().toLowerCase();
    const normalizeLettersOnly = (str) =>
      str
        .replace(/[0-9\s]+/g, "")
        .trim()
        .toLowerCase();
    const normalizedInput = normalizeLettersAndNumbers(inputValue);
    const normalizedAnswer = normalizeLettersAndNumbers(quizQuestions[0]?.hanzi_ptext);
    const normalizedLettersInput = normalizeLettersOnly(inputValue);
    const normalizedLettersAnswer = normalizeLettersOnly(quizQuestions[0]?.hanzi_ptext);
    if (inputValue.includes("0")) {
      // console.log("0 detected in inputValue, enabling answer boxes...");
      setTimeout(() => {
        setEnableAnswerBoxes(true);
      }, 0); // Delay to ensure DOM is updated
      if (quizCat !== "radMini") {
        handleFlip(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
      } else {
        handleFlip(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
      }
      inputValue = inputValue.replace(/0/g, ""); // Remove all 0s from the input
    }
    if (inputValue.includes("9")) {
      if (quizCat !== "radMini") {
        handlePlayAudio(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
      } else {
        handlePlayAudio(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
      }
      inputValue = inputValue.replace(/9/g, ""); // Remove all 9s from the input
    }
    if (inputValue.includes("!")) {
      if (enableAnswerBoxes) {
        handleAnswer(1);
      }
      inputValue = "";
    } else if (inputValue.includes("@")) {
      if (enableAnswerBoxes) {
        handleAnswer(2);
      }
      inputValue = "";
    } else if (inputValue.includes("#")) {
      if (enableAnswerBoxes) {
        handleAnswer(3);
      }
      inputValue = "";
    } else if (inputValue.includes("$")) {
      if (enableAnswerBoxes) {
        handleAnswer(4);
      }
      inputValue = "";
    } else if (inputValue.includes("%")) {
      if (enableAnswerBoxes) {
        handleAnswer(5);
      }
      inputValue = "";
    } else if (inputValue.includes("^")) {
      if (enableAnswerBoxes) {
        handleAnswer(6);
      }
      inputValue = "";
    }
    if (normalizedInput === normalizedAnswer) {
      if (quizCat !== "radMini") {
        handleFlip(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type);
      } else {
        handleFlip(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type);
      }
      setPinyinMatch(true);
      setPinyinLetterMatch(false);
      setEnableAnswerBoxes(true);
    } else if (normalizedLettersInput === normalizedLettersAnswer) {
      setPinyinMatch(false);
      setPinyinLetterMatch(true);
      setEnableAnswerBoxes(false);
    } else {
      setPinyinMatch(false);
      setPinyinLetterMatch(false);
      setEnableAnswerBoxes(false);
    }
    setPinyinInput(inputValue);
  };

  // useEffect(() => {
  //   console.log("EnableAnswerBoxes state:", enableAnswerBoxes);
  // }, [enableAnswerBoxes]);

  const handleFlagSave = async (action) => {
    if (!quizQuestions) return;
    const flagParams = {
      action: action,
      flagId: flagId,
      hanzi: quizQuestions[0]?.hanzi_id,
      user: currentUser.id,
      note: action === "add" || action === "update" ? flagNote : null,
    };
    try {
      const response = await axiosPrivate.post(`/questionFlag`, flagParams);
      console.log("Flag submitted:", flagParams, "Response:", response.data);
      if (response.data.flagId !== undefined) {
        setFlagId(response.data.flagId);
      }
      setFlagging(false); // Exit flagging mode
    } catch (error) {
      console.error("Error modifying flag:", error);
    } finally {
      if (quizType !== "Sound" && clickMode === 0) {
        setTimeout(() => {
          pinyinTextFieldRef.current.focus();
        }, 0); // Delay to ensure DOM is updated
      }
      if (flagNote === "") {
        setFlagFlag(0);
      } else {
        setFlagFlag(1);
      }
      setTimeout(() => {
        setEnableAnswerBoxes(true);
      }, 0);
    }
  };

  const handleFlagIconOpen = () => {
    setDiscussing(false); // Exit discussing mode
    setFlagging(true);
    setEnableAnswerBoxes(false); // Disable answer buttons
    // Make the flag text field focus after the DOM updates
    setTimeout(() => {
      flagTextFieldRef.current?.focus();
    }, 0); // Use a small timeout (0ms) to ensure the DOM updates
  };

  const handleFlagIconFlag = async () => {
    const newFlagFlag = flagFlag === 1 ? 0 : 1;
    try {
      await axiosPrivate.get(`/questionFlagToggle?flagId=${flagId}&flagFlag=${newFlagFlag}`);
    } catch (error) {
      console.error("Error toggling flag:", error);
    }
    setFlagFlag(newFlagFlag);
  };

  const handleDiscussSave = async (action) => {
    if (!quizQuestions) return;
    const discussParams = {
      action: action,
      discussId: discussId,
      hanzi: quizQuestions[0]?.hanzi_id,
      user: currentUser.id,
      note: action === "add" || action === "update" ? discussNote : null,
    };
    try {
      const response = await axiosPrivate.post(`/questionDiscuss`, discussParams);
      console.log("Discussion submitted:", discussParams, "Response:", response.data);
      if (response.data.discussId !== undefined) {
        setDiscussId(response.data.discussId);
      }
      setDiscussing(false); // Exit discussing mode
    } catch (error) {
      console.error("Error modifying discuss:", error);
    } finally {
      if (quizType !== "Sound" && clickMode === 0) {
        setTimeout(() => {
          pinyinTextFieldRef.current.focus();
        }, 0); // Delay to ensure DOM is updated
      }
      if (discussNote === "") {
        setDiscussNotify(0);
      } else {
        setDiscussNotify(1);
      }
      setTimeout(() => {
        setEnableAnswerBoxes(true);
      }, 0);
    }
  };

  const handleDiscussIconOpen = () => {
    setFlagging(false); // Exit flagging mode
    setDiscussing(true);
    setEnableAnswerBoxes(false); // Disable answer buttons
    // Make the discuss text field focus after the DOM updates
    setTimeout(() => {
      discussTextFieldRef.current?.focus();
    }, 0); // Use a small timeout (0ms) to ensure the DOM updates
  };

  const handleDiscussIconNotify = async () => {
    const newDiscussNotify = discussNotify === 1 ? 0 : 1;
    try {
      await axiosPrivate.get(`/questionDiscussToggle?discussId=${discussId}&discussNotify=${newDiscussNotify}`);
    } catch (error) {
      console.error("Error toggling discuss notify:", error);
    }
    setDiscussNotify(newDiscussNotify);
  };

  const handlePriority = async () => {
    if (!quizQuestions) return;
    let priorityParams;
    try {
      if (priorityId > 0) {
        // Delete entry from the priority table
        priorityParams = `?action=delete&priorityId=${priorityId}`;
        const response = await axiosPrivate.get(`/questionPriority${priorityParams}`);
        console.log("Priority deleted:", response.data);
        setPriorityId(0);
      } else {
        // Add entry to the priority table
        priorityParams = `?action=add&hanzi=${quizQuestions[0]?.hanzi_id}&user=${currentUser.id}`;
        const response = await axiosPrivate.get(`/questionPriority${priorityParams}`);
        console.log("Priority added:", response.data);
        setPriorityId(response.data.priorityId);
      }
    } catch (error) {
      console.error("Error modifying priority:", error);
    } finally {
      if (quizType !== "Sound" && clickMode === 0) {
        setTimeout(() => {
          pinyinTextFieldRef.current.focus();
        }, 0); // Delay to ensure DOM is updated
      }
    }
  };

  const handleAnswer = async (score) => {
    if (!quizQuestions) return;
    console.log("Score: ", score);
    setCardLoading(true);
    setFlipped(false);
    setFlippedOnce(false);
    setPinyinInput("");
    setDisableAnimation(true);
    setEnableAnswerBoxes(false);
    setPartsData([]);
    setSubPartsData([]);
    setUsesData([]);
    setPhrasesData([]);
    setParonymData([]);
    setShuffledSoundAnswers([]);
    setSelectedCorrectSoundAnswer(null);
    setIncorrectSoundAnswers([]);
    setIncorrectSoundAttempts(0);
    audioPlayed.current = false;
    const answerPayload = {
      hanziId: quizQuestions[0]?.hanzi_id,
      userId: currentUser.id,
      quizCat,
      quizType,
      score,
    };
    try {
      await axiosPrivate.post("/quizAnswers", answerPayload);
      console.log("Answer submitted:", answerPayload);
      const questionsResponse = await axiosPrivate.get(
        `/quizQuestions?user=${currentUser.id}&cat=${quizCat}&type=${quizType}`
      );
      // If no more questions, navigate back
      // console.log("Remaining: ", questionsResponse.data.length);
      if (questionsResponse.data.length === 0) {
        console.log("No more questions available. Navigating back...");
        try {
          await axiosPrivate.get(
            `/quizExit?user=${currentUser.id}&cat=${quizCat}&type=${quizType}&remaining=${questionsResponse.data.length}`
          );
        } catch (error) {
          console.error("Failed to log quiz exit:", error);
          // Optionally continue or halt navigation based on error severity
        }
        if (quizCat.startsWith("Duo")) {
          // Extract the section number (the single digit before ":")
          const sectionMatch = quizCat.match(/^Duo(\d+):/);
          const section = sectionMatch ? sectionMatch[1] : null;
          if (section) {
            navigate("/quiz/duolingo", { state: { section } });
            return;
          }
        }
        if (quizCat.startsWith("Dom")) {
          const courseMatch = quizCat.match(/^Dom(\d+):/);
          const course = courseMatch ? courseMatch[1] : null;
          if (course) {
            console.log("Navigating to Dom course:", course);
            navigate("/quiz/domino", { state: { course } });
            return;
          }
        }
        navigate(-1); // Navigate to the previous page
        return;
      }
      console.log(`678 New Hanzi ID: ${questionsResponse.data[0].hanzi_id}, Char: ${questionsResponse.data[0].hanzi_character},
        Priority_id: ${questionsResponse.data[0].priority_id}, Flag_flag: ${questionsResponse.data[0].flag_flag}, 
        Flag_note: ${questionsResponse.data[0].flag_note}, Discuss_id: ${questionsResponse.data[0].discuss_id},`);

      setQuizQuestions(questionsResponse.data);
      const partsPromises = Array.from({ length: 10 }, (_, i) => {
        const partId = questionsResponse.data[0][`hanzi_part${i}`];
        return partId ? axiosPrivate.get(`/questParts?hanzi=${partId}`) : Promise.resolve(null);
      });

      const partsResults = await Promise.all(partsPromises);
      const parts = partsResults.map((result) => result?.data?.[0]).filter(Boolean);
      setPartsData(parts);
      // Fetch subparts for all parts
      const subPartsPromises = parts.map((part, index) =>
        Promise.all(
          Array.from({ length: 10 }, (_, i) => {
            const subPartId = part[`hanzi_part${i}`];
            return subPartId ? axiosPrivate.get(`/questParts?hanzi=${subPartId}`) : Promise.resolve(null);
          })
        )
      );
      const subPartsResults = await Promise.all(subPartsPromises);
      const subParts = subPartsResults.map((results) => results.map((result) => result?.data?.[0]).filter(Boolean));
      // Update state with subparts data
      const subPartsDataMap = {};
      subParts.forEach((subPartArray, index) => {
        subPartsDataMap[index] = subPartArray;
      });
      setSubPartsData(subPartsDataMap);

      // Fetch uses and phrases
      const usesResponse = await axiosPrivate.get(
        `/questUses?hanzi=${questionsResponse.data[0]["hanzi_id"]}&type=${questionsResponse.data[0]["hanzi_type"]}&character=${questionsResponse.data[0]["hanzi_character"]}`
      );
      // console.log("usesResponse.data", JSON.stringify(usesResponse.data));
      setUsesData(usesResponse.data);
      const phrasesResponse = await axiosPrivate.get(
        `/questPhrases?character=${questionsResponse.data[0]["hanzi_character"]}&id=${questionsResponse.data[0]["hanzi_id"]}`
      );
      // console.log("phrasesResponse.data", JSON.stringify(phrasesResponse.data));
      setPhrasesData(phrasesResponse.data);
      if (questionsResponse.data[0].hanzi_type < 3) {
        const ptext = questionsResponse.data[0].hanzi_ptext;
        const letters = ptext.replace(/\d+$/, ""); // Removes any trailing digit
        const id = questionsResponse.data[0].hanzi_id;
        console.log("questParonyms letters:", letters);
        const paronymResponse = await axiosPrivate.get(`/questParonyms?letters=${letters}&id=${id}`);
        // console.log("paronymResponse.data", JSON.stringify(paronymResponse.data));
        setParonymData(paronymResponse.data);
      }

      quizCat === "Speechmeasure"
        ? setQuizMeaning(questionsResponse.data[0].hanzi_measure)
        : setQuizMeaning(questionsResponse.data[0].hanzi_meaning);
      if (questionsResponse.data[0].priority_id > 0) {
        setPriorityId(questionsResponse.data[0].priority_id);
      } else {
        setPriorityId(0);
      }
      if (questionsResponse.data[0].flag_id > 0) {
        setFlagId(questionsResponse.data[0].flag_id);
        setFlagNote(questionsResponse.data[0].flag_note);
        setFlagFlag(questionsResponse.data[0].flag_flag);
      } else {
        setFlagId(0);
        setFlagNote("");
        setFlagFlag(0);
      }
      if (questionsResponse.data[0].discuss_id > 0) {
        setDiscussId(questionsResponse.data[0].discuss_id);
        setDiscussNote(questionsResponse.data[0].discuss_note);
        setDiscussNotify(questionsResponse.data[0].discuss_notify);
      } else {
        setDiscussId(0);
        setDiscussNote("");
        setDiscussNotify(0);
      }
      setSoundScore(6);
      setFlagging(false);
      setDiscussing(false);
      // Trigger a re-render
      setRenderKey((prevKey) => prevKey + 1);
      setPinyinLetterMatch(false);
      setPinyinMatch(false);
      const scoreResponse = await axiosPrivate.get(`/quizScore?user=${currentUser.id}&cat=${quizCat}&type=${quizType}`);
      console.log("Score: ", scoreResponse.data[0]);
      setQuizScore(scoreResponse.data[0]);
      setTimeout(() => setDisableAnimation(false), 500);
      setRemainingTime(currentUser.timer); // Reset timer to the original value
      //       currentUser.timer > 0 && restartTimer(); // Restart timer if it's enabled >> commented out on 2/27/25
      // console.log("Quiz questions refreshed:", questionsResponse.data);
      if (quizType === "Sound" && questionsResponse.data[0]?.hanzi_character) {
        console.log("Audio 785: ", questionsResponse.data[0]?.hanzi_character);
        audioPlayed.current = true;
        if (quizCat !== "radMini") {
          handlePlayAudio(questionsResponse.data[0]?.hanzi_character, questionsResponse.data[0]?.hanzi_type);
        } else {
          handlePlayAudio(questionsResponse.data[0]?.hanzi_char_full, questionsResponse.data[0]?.hanzi_type);
        }
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
    } finally {
      if (quizType !== "Sound" && clickMode === 0) {
        setTimeout(() => {
          pinyinTextFieldRef.current.focus();
        }, 0); // Delay to ensure DOM is updated
      }
      setCardLoading(false);
    }
  };

  if (loading) {
    return (
      <Typography variant="body1" sx={{ textAlign: "center", mt: 5 }}>
        Loading...
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        margin: 0,
        padding: 0,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "98%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          bgcolor: "background.default",
          color: "text.primary",
          margin: "auto",
          px: 2,
        }}
      >
        {/* Header with Section Info */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            alignItems: "center",
            mb: 2,
            mt: 2,
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "left", flex: 1 }}>
            {quizCat.startsWith("Rad")
              ? (() => {
                  const strippedQuizCat = quizCat.replace("Rad", "").trim(); // Remove "Rad"
                  const baseCategory = strippedQuizCat.includes("99")
                    ? `${strippedQuizCat.split(":")[0]}+`
                    : strippedQuizCat.split(":")[0] === strippedQuizCat.split("-")[1]
                    ? strippedQuizCat.split(":")[0]
                    : strippedQuizCat;

                  return `${baseCategory} ${quizCat === "1:1" ? "Use" : "Uses"}`;
                })()
              : quizCat.startsWith("Speech")
              ? (() => {
                  const strippedSpeechCat = quizCat.replace("Speech", "").trim(); // Remove "Speech"
                  return strippedSpeechCat.charAt(0).toUpperCase() + strippedSpeechCat.slice(1); // Capitalize first letter
                })()
              : quizCat}
          </Typography>
          {currentUser.timer > 0 && (
            <Typography
              variant="body1"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: "bold",
                color: getTimerColor(),
              }}
            >
              00:{formatTime(remainingTime)}
            </Typography>
          )}
          <Typography variant="body1" sx={{ textAlign: "right", flex: 1 }}>
            {quizScore.average_score ? `${Math.round(quizScore.average_score)}%` : "0%"} ~ {quizQuestions.length} rem
          </Typography>
        </Box>

        {/* Question and Answer Box */}
        {/* Flip Container */}
        <Box
          onClick={() =>
            quizCat !== "radMini"
              ? handleFlip(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type)
              : handleFlip(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type)
          }
          sx={{
            width: "100%",
            height: 200,
            border: "1px solid #ccc",
            borderRadius: "18px",
            perspective: "1000px", // Create 3D perspective for flip effect
            // overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "2px solid #000",
              borderRadius: "18px",
              bgcolor: "background.paper",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: 4,
              boxSizing: "border-box", // Ensure inner box respects dimensions
              transformStyle: "preserve-3d",
              transition: disableAnimation ? "none" : "transform 0.6s",
              transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)", // Rotate based on flip state
            }}
          >
            {/* Front Face */}
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box", // Ensure inner box respects dimensions
              }}
            >
              {!cardLoading && (
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: quizType === "Meaning" ? "20px" : "24px",
                    fontWeight: 300,
                    fontFamily:
                      quizType === "Character"
                        ? '"Hiragino Mincho ProN", "Songti SC", "Noto Serif SC", serif'
                        : quizType === "Sound"
                        ? '"Spectral"'
                        : "Roboto, Helvetica, Arial, sans-serif",
                    px: quizType === "Character" && quizQuestions[0]?.hanzi_character.length > 5 ? 4 : 0,
                  }}
                >
                  {quizType === "Character" ? (
                    <Box display="flex" flexWrap="wrap" gap={0} justifyContent="center">
                      {quizQuestions[0].hanzi_character
                        .replace(/\s/g, "")
                        .split("")
                        .map((char, index, array) => (
                          <React.Fragment key={index}>
                            <HanziCharacter
                              character={char}
                              width={array.length > 7 ? 64 : array.length > 3 ? 72 : 96}
                              height={array.length > 7 ? 64 : array.length > 3 ? 72 : 96}
                            />

                            {/* Insert "or" between type=1 (radicals) with multiple characters */}
                            {quizQuestions[0].hanzi_type === 1 && array.length > 1 && index < array.length - 1 && (
                              <Box
                                sx={{
                                  alignSelf: "center",
                                  mx: 0.5,
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                              >
                                or
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                    </Box>
                  ) : quizType === "Meaning" ? (
                    quizMeaning
                  ) : (
                    quizType === "Sound" &&
                    pinyinWithHighlight.map(({ text, bold }, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: bold ? "normal" : "normal",
                          textDecoration: bold ? "underline" : "none",
                        }}
                      >
                        {text}
                        {index < pinyinWithHighlight.length - 1 && " "} {/* Add space between words */}
                      </span>
                    ))
                  )}
                </Typography>
              )}
            </Box>

            {/* Back Face */}
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                transform: "rotateY(180deg)", // Rotate content to back
                display: "flex",
                flexDirection: "column", // Arrange top and bottom sections vertically
                justifyContent: "space-between", // Space top and bottom sections
                alignItems: "center",
                boxSizing: "border-box",
                pl: 2,
                pr: 2,
              }}
            >
              {/* Top Section */}
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {/* Box to contain hanzi character once or twice in two fonts */}
                <Box
                  sx={{
                    display: quizQuestions[0]?.hanzi_character?.length < 4 ? "flex" : "block",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {/* If Character QuizType and length < 4 */}
                  {quizQuestions[0]?.hanzi_character?.length < 4 ? (
                    <>
                      {/* Left Block - Serif Font */}
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: '"Hiragino Mincho ProN", "Songti SC", "Noto Serif SC", serif',
                            fontSize: quizQuestions[0]?.hanzi_character?.length === 3 ? "48px" : "56px",
                            fontWeight: 300,
                            mb: quizQuestions[0]?.hanzi_character?.length === 3 ? 0.5 : -1,
                            padding: 0,
                          }}
                        >
                          {quizQuestions[0]?.hanzi_character}
                        </Typography>
                      </Box>
                      {/* Right Block - Sans-Serif Font */}
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: '"Arial", sans-serif',
                            fontSize: quizQuestions[0]?.hanzi_character?.length === 3 ? "48px" : "56px",
                            fontWeight: 300,
                            mb: quizQuestions[0]?.hanzi_character?.length === 3 ? 0.5 : -1,
                            padding: 0,
                          }}
                        >
                          {quizQuestions[0]?.hanzi_character}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* If Character QuizType and length >= 4, display only one hanzi font */}
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: '"Hiragino Mincho ProN", "Songti SC", "Noto Serif SC", serif',
                          fontSize:
                            quizQuestions[0]?.hanzi_character?.length > 9
                              ? "30px"
                              : quizQuestions[0]?.hanzi_character?.length > 7
                              ? "32px"
                              : quizQuestions[0]?.hanzi_character?.length > 4
                              ? "40px"
                              : quizQuestions[0]?.hanzi_character?.length > 3
                              ? "48px"
                              : "56px",
                          fontWeight: 300,
                          mb: -1,
                          padding: 0,
                        }}
                      >
                        {quizQuestions[0]?.hanzi_character}
                      </Typography>
                    </>
                  )}
                </Box>
                <Typography
                  variant="body1"
                  onClick={
                    audioPlaying
                      ? null
                      : () =>
                          quizCat !== "radMini"
                            ? handlePlayAudio(quizQuestions[0]?.hanzi_character, quizQuestions[0]?.hanzi_type)
                            : handlePlayAudio(quizQuestions[0]?.hanzi_char_full, quizQuestions[0]?.hanzi_type)
                  }
                  sx={{
                    fontFamily: "Spectral",
                    fontSize: quizQuestions[0]?.hanzi_pinyin.length > 24 ? "24px" : "28px",
                    fontWeight: 300,
                    color: "primary.main",
                    cursor: "pointer",
                    textDecoration: "none",
                    "&:hover": {
                      color: "primary.dark", // Change color on hover for better feedback
                    },
                    opacity: audioPlaying ? 0.7 : 1,
                    mt:
                      quizQuestions[0]?.hanzi_character?.length > 6
                        ? 2
                        : quizQuestions[0]?.hanzi_character?.length > 4
                        ? 2
                        : 0,
                    mb:
                      quizQuestions[0]?.hanzi_character?.length > 6
                        ? 2
                        : quizQuestions[0]?.hanzi_character?.length > 4
                        ? 2
                        : 1,
                    pl: quizQuestions[0]?.hanzi_pinyin.length > 24 ? 3 : 0,
                    pr: quizQuestions[0]?.hanzi_pinyin.length > 24 ? 3 : 0,
                    lineHeight: quizQuestions[0]?.hanzi_pinyin.length > 24 ? 0.9 : 1,
                  }}
                >
                  {quizType !== "Sound"
                    ? quizQuestions[0]?.hanzi_pinyin
                    : pinyinWithHighlight.map(({ text, bold }, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: bold ? "normal" : "normal",
                            textDecoration: bold ? "underline" : "none",
                          }}
                        >
                          {text}
                          {index < pinyinWithHighlight.length - 1 && " "} {/* Add space between words */}
                        </span>
                      ))}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Arial",
                    fontSize: "20px",
                    fontWeight: 300,
                    mt: 1,
                    mb: 0,
                    pt: quizMeaning && quizMeaning.length > 38 ? 0 : 1,
                    pl: quizMeaning && quizMeaning.length > 38 ? 3 : 0,
                    pr: quizMeaning && quizMeaning.length > 38 ? 3 : 0,
                    lineHeight: quizMeaning && quizMeaning.length > 38 ? 0.9 : 1,
                  }}
                >
                  {quizMeaning}
                </Typography>
              </Box>

              {/* Bottom Section */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between", // Space items evenly
                }}
              >
                {/* Each Box takes up one-third of the width */}
                <Box
                  sx={{
                    width: "33.33%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial",
                      fontSize: "16px",
                      fontWeight: 300,
                    }}
                  >
                    HSK:{" "}
                    {quizQuestions[0]?.hanzi_hsk_level === 0
                      ? quizQuestions[0]?.hanzi_hsk_comp
                      : quizQuestions[0]?.hanzi_hsk_level}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "33.33%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial",
                      fontSize: "16px",
                      fontWeight: 300,
                    }}
                  >
                    Duo: {quizQuestions[0]?.hanzi_duo_section}:
                    {parseFloat(quizQuestions[0]?.hanzi_duo_unit) % 1 === 0
                      ? Math.floor(quizQuestions[0]?.hanzi_duo_unit)
                      : quizQuestions[0]?.hanzi_duo_unit}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "33.33%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial",
                      fontSize: quizQuestions[0]?.hanzi_dom_lesson.length > 1 ? "15px" : "16px",
                      fontWeight: 300,
                    }}
                  >
                    Dom: {quizQuestions[0]?.hanzi_dom_course}:{quizQuestions[0]?.hanzi_dom_chapter}:
                    {quizQuestions[0]?.hanzi_dom_lesson || 0}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", pt: 1 }}>
          {/* Function Buttons */}
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Box
                onClick={flagging ? handleFlagIconFlag : handleFlagIconOpen}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) => (flagFlag > 0 ? theme.palette.error.main : theme.palette.grey[700]),
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Flag />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                onClick={quizType !== "Sound" ? () => setEnableAnswerBoxes(true) : null}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) => theme.palette.grey[700],
                  borderRadius: "4px",
                  cursor: quizType === "Sound" ? "not-allowed" : "pointer",
                  opacity: quizType === "Sound" ? 0.5 : 1,
                }}
              >
                <FlashOn />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                onClick={discussing ? handleDiscussIconNotify : handleDiscussIconOpen}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) =>
                    discussNote && discussNote.length > 0 ? theme.palette.success.main : theme.palette.grey[700],
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Comment />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                onClick={() => flippedOnce && setFlipped((prev) => !prev)}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) => theme.palette.grey[700],
                  borderRadius: "4px",
                  cursor: flippedOnce ? "pointer" : "not-allowed",
                  opacity: flippedOnce ? 1 : 0.5,
                }}
              >
                <Undo />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                onClick={handlePriority}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) => (priorityId > 0 ? theme.palette.error.main : theme.palette.grey[700]),
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <PriorityHigh />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                onClick={async () => {
                  try {
                    await axiosPrivate.get(
                      `/quizExit?user=${currentUser.id}&cat=${quizCat}&type=${quizType}&remaining=${quizQuestions.length}`
                    );
                    // After axios request is completed, navigate back
                    if (quizCat.startsWith("Duo")) {
                      const sectionMatch = quizCat.match(/^Duo(\d+):/);
                      const section = sectionMatch ? sectionMatch[1] : null;
                      if (section) {
                        console.log("Navigating to Duo section:", section);
                        navigate("/quiz/duolingo", { state: { section } });
                        return;
                      }
                    }
                    if (quizCat.startsWith("Dom")) {
                      const courseMatch = quizCat.match(/^Dom(\d+):/);
                      const course = courseMatch ? courseMatch[1] : null;
                      if (course) {
                        console.log("Navigating to Dom course:", course);
                        navigate("/quiz/domino", { state: { course } });
                        return;
                      }
                    }
                    navigate(-1);
                  } catch (error) {
                    console.error("Error submitting request:", error);
                  }
                }}
                sx={{
                  width: "100%",
                  height: "50px",
                  bgcolor: (theme) => theme.palette.grey[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: (theme) => theme.palette.grey[700],
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Cancel />
              </Box>
            </Grid>
          </Grid>

          {/* Input Field */}
          <Box sx={{ mt: 1, pl: 2, pr: 2 }}>
            {flagging ? (
              <Box sx={{ mt: 1, display: "flex", alignItems: "center", gap: 1 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Enter Flag Note"
                  value={flagNote}
                  onChange={(e) => setFlagNote(e.target.value)}
                  inputProps={{ maxLength: 500 }}
                  inputRef={flagTextFieldRef}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: (theme) => theme.palette.error.main }, // Red outline for flagging mode
                    },
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleFlagSave(flagId > 0 ? "update" : "add")}
                >
                  Save
                </Button>
              </Box>
            ) : discussing ? (
              <Box sx={{ mt: 1, display: "flex", alignItems: "center", gap: 1 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Enter Discussion Note"
                  value={discussNote}
                  onChange={(e) => setDiscussNote(e.target.value)}
                  inputProps={{ maxLength: 500 }}
                  inputRef={discussTextFieldRef}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: (theme) => theme.palette.error.main }, // Red outline for discussing mode
                    },
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDiscussSave(discussId > 0 ? "update" : "add")}
                >
                  Save
                </Button>
              </Box>
            ) : quizType !== "Sound" && clickMode === 0 ? (
              <TextField
                fullWidth
                variant="outlined"
                label="Enter Pinyin Text"
                inputProps={{ autoCapitalize: "none", spellCheck: false }}
                value={pinyinInput}
                onChange={(event) => {
                  setPinyinInput(event.target.value); // Update state on input change
                  handlePinyinInput(event); // Existing logic
                }}
                inputRef={pinyinTextFieldRef}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: (theme) =>
                        pinyinMatch
                          ? theme.palette.success.main
                          : pinyinLetterMatch
                          ? theme.palette.warning.main
                          : theme.palette.info.main, // Outline color
                    },
                    "&:hover fieldset": {
                      borderColor: (theme) =>
                        pinyinMatch
                          ? theme.palette.success.main
                          : pinyinLetterMatch
                          ? theme.palette.warning.main
                          : theme.palette.info.main, // Outline color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: (theme) =>
                        pinyinMatch
                          ? theme.palette.success.main
                          : pinyinLetterMatch
                          ? theme.palette.warning.main
                          : theme.palette.info.main, // Outline color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: (theme) =>
                      pinyinMatch
                        ? theme.palette.success.main
                        : pinyinLetterMatch
                        ? theme.palette.warning.main
                        : theme.palette.info.main, // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: (theme) =>
                      pinyinMatch
                        ? theme.palette.success.main
                        : pinyinLetterMatch
                        ? theme.palette.warning.main
                        : theme.palette.info.main, // Label color when focused
                  },
                  mt: 1,
                }}
              />
            ) : null}
          </Box>

          {/* Answer Buttons */}
          <Box sx={{ width: "100%", pt: 1 }}>
            <Grid container spacing={1}>
              {quizType === "Sound"
                ? shuffledSoundAnswers.map((answerObj, index) => (
                    <Grid item xs={2} key={index}>
                      <Box
                        onClick={
                          // If correct answer already selected or 3 incorrect attempts, disable button
                          selectedCorrectSoundAnswer || incorrectSoundAnswers.includes(answerObj.character)
                            ? null
                            : () => handleSoundAnswer(answerObj.character, answerObj.type)
                        }
                        sx={{
                          width: "100%",
                          height: "50px",
                          bgcolor: (theme) =>
                            selectedCorrectSoundAnswer === answerObj.character
                              ? theme.palette.success.main
                              : incorrectSoundAttempts > 0 && incorrectSoundAnswers.includes(answerObj.character)
                              ? theme.palette.error.main
                              : "primary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          borderRadius: "6px",
                          cursor:
                            selectedCorrectSoundAnswer || incorrectSoundAnswers.includes(answerObj.character)
                              ? "not-allowed"
                              : "pointer",
                          opacity: incorrectSoundAnswers.includes(answerObj.character) ? 0.5 : 1,
                          // border:
                          //   selectedCorrectSoundAnswer === answerObj.character ||
                          //   (answerObj.character === quizQuestions[0]?.hanzi_character &&
                          //     incorrectSoundAttempts >= 3) ||
                          //   (incorrectSoundAttempts > 0 && incorrectSoundAnswers.includes(answerObj.character))
                          //     ? "2px solid"
                          //     : "none",
                          // borderColor: (theme) =>
                          //   selectedCorrectSoundAnswer === answerObj.character
                          //     ? theme.palette.success.main
                          //     : answerObj.character === quizQuestions[0]?.hanzi_character && incorrectSoundAttempts >= 3
                          //     ? theme.palette.success.main
                          //     : incorrectSoundAttempts > 0 && incorrectSoundAnswers.includes(answerObj.character)
                          //     ? theme.palette.error.main
                          //     : "transparent",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontSize: "32px", fontWeight: 300 }}>
                          {answerObj.character}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                : ["一", "二", "三", "四", "五", "六"].map((char, index) => (
                    <Grid item xs={2} key={index}>
                      <Box
                        onClick={enableAnswerBoxes ? () => handleAnswer(index + 1) : null}
                        sx={{
                          width: "100%",
                          height: "50px",
                          bgcolor: "primary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          borderRadius: "6px",
                          fontSize: "30px",
                          cursor: enableAnswerBoxes ? "pointer" : "not-allowed",
                          opacity: enableAnswerBoxes ? 1 : 0.5,
                          transition: "all 0.3s ease",
                        }}
                      >
                        {char}
                      </Box>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {flippedOnce ? (
        <Grid
          container
          spacing={0}
          sx={{
            mt: 2,
            alignSelf: "stretch",
            justifyContent: quizCat !== "radMini" ? { xs: "center", md: "space-between" } : "center",
            alignItems: quizCat !== "radMini" ? "flex-start" : "center",
            height: "auto",
          }}
          justifyContent="space-between"
          alignItems="flex-start" // Align items to the top
        >
          {quizCat !== "radMini" && (
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
                // border: "1px solid success.main",
                height: "auto",
              }}
            >
              {/* Building Blocks Accordion */}
              <Box
                key={renderKey}
                sx={{
                  maxWidth: "600px",
                  width: "100%",
                  margin: 0,
                  py: 0,
                  pl: { xs: 0, md: 2 },
                  pr: { xs: 0, md: 1 },
                  textAlign: { xs: "center", md: "right" },
                }}
              >
                {/* Measure Accordion */}
                {quizQuestions[0].hanzi_measure && quizQuestions[0].hanzi_measure.length > 0 && (
                  <Accordion sx={{ mb: 2, bgcolor: (theme) => theme.palette.darkPaper }} defaultExpanded>
                    <AccordionSummary sx={{ mt: 0 }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                          Measure
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 0 }}>
                      <Typography variant="body1" sx={{ textAlign: "left", ml: 2, mb: 1 }}>
                        {quizQuestions[0].hanzi_measure.charAt(0).toUpperCase() +
                          quizQuestions[0].hanzi_measure.slice(1)}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion defaultExpanded>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Building Blocks
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        {partsData.length}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ py: 0 }}>
                    {partsData.map((part, index) =>
                      subPartsData[index]?.length > 0 ? ( // Only render the Accordion if there are subparts
                        <Accordion key={index} defaultExpanded>
                          <AccordionSummary sx={{ my: -1 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                              {renderParts(part)}
                              <Typography variant="body2" sx={{ textAlign: "right" }}>
                                {subPartsData[index]?.length || 0}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{ py: 0 }}>
                            {subPartsData[index].map((subPart, subIndex) => (
                              <Box key={subIndex}>{renderSubParts(subPart)}</Box>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Box key={index}>
                          <AccordionSummary sx={{ my: 0 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                              {renderParts(part)}
                              <Typography variant="body2" sx={{ textAlign: "right" }}>
                                0
                              </Typography>
                            </Box>
                          </AccordionSummary>
                        </Box>
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: quizCat !== "radMini" ? { xs: "center", md: "flex-start" } : "center",
              justifyContent: "center",
              // border: "1px solid success.main",
              height: "auto",
            }}
          >
            <Box
              key={renderKey}
              sx={{
                maxWidth: "600px",
                width: "100%",
                margin: 0,
                py: 0,
                pl: { xs: 0, md: 1 },
                pr: { xs: 0, md: 2 },
                textAlign: "left",
              }}
            >
              {/* Uses Accordion */}
              {usesData.length > 0 ? (
                <Accordion sx={{ my: { xs: 2, md: 0 } }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Uses
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        {usesData.length}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ py: 0 }}>
                    {usesData.map((use, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        {renderParts(use)}
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion sx={{ my: { xs: 2, md: 0 } }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", my: { xs: 2, md: 0 } }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Uses
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        0
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
              )}

              {/* Phrases Accordion */}
              {phrasesData.length > 0 ? (
                <Accordion sx={{ my: 2 }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Phrases
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        {phrasesData.length}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ py: 0 }}>
                    {phrasesData.map((phrase, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        {renderParts(phrase)}
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion sx={{ my: 2 }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Phrases
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        0
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
              )}

              {/* Paronym Accordion */}
              {paronymData.length > 0 ? (
                <Accordion sx={{ my: 2 }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Paronyms
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        {paronymData.length}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ py: 0 }}>
                    {paronymData.map((paronym, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        {renderParts(paronym)}
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion sx={{ my: 2 }}>
                  <AccordionSummary sx={{ mt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "left" }}>
                        Paronyms
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, textAlign: "right" }}>
                        0
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default Quiz;
