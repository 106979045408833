import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar, Button, IconButton, Avatar, Tooltip, Typography } from "@mui/material";
import { Home } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";

const TopNavBarPublic = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar
        sx={{
          // backgroundColor: "hsl(39,100%,95%)",
          backgroundColor: "rgb(255, 255, 255)",
          border: "none",
          minHeight: "56px !important",
          height: "56px !important",
          pl: "8px !important",
        }}
      >
        <Tooltip title="Go back to home page">
          <IconButton edge="start" sx={{ ml: 0 }} onClick={() => navigate("/")}>
            <Home sx={{ color: "hsl(37,100%,60%)" }} />
          </IconButton>
        </Tooltip>
        <Typography
          color="primary"
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, ml: 1, mt: 0.25, fontWeight: 600, color: "rgb(56, 56, 56)" }}
        >
          HSK Cards
        </Typography>
        <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          {currentUser.accessToken ? (
            <IconButton onClick={() => navigate("/user")}>
              {currentUser?.photoURL ? (
                <Avatar src={currentUser.photoURL} alt={currentUser?.name || "User Avatar"} />
              ) : (
                <Avatar>{currentUser?.firstName?.charAt(0).toUpperCase() || "U"}</Avatar>
              )}
            </IconButton>
          ) : (
            <>
              <Button
                color="primary"
                onClick={() => navigate("/login")}
                sx={{
                  mr: 2,
                  py: { xs: 1, sm: 1.25 },
                  px: { xs: 1, sm: 2 },
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "none",
                  fontWeight: 400,
                  fontSize: "1rem",
                  textTransform: "none",
                  fontFamily: "Arial",
                  "&:hover": {
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "rgb(0,0,0)",
                  },
                }}
              >
                Login
              </Button>
              <Button
                color="primary"
                onClick={() => navigate("/register")}
                sx={{
                  mr: 2,
                  py: { xs: 1, sm: 1.25 },
                  px: { xs: 1, sm: 2 },
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "none",
                  fontWeight: 400,
                  fontSize: "1rem",
                  textTransform: "none",
                  fontFamily: "Arial",
                  "&:hover": {
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "rgb(0,0,0)",
                  },
                }}
              >
                Register
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBarPublic;
