// src/Home.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Divider, Box, Container, Grid, TextField, Dialog } from "@mui/material";
import { List, ListItem, ListItemIcon, useMediaQuery, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const Home = () => {
  const navigate = useNavigate();
  const [quizOpen, setQuizOpen] = useState(false);
  const [studyOpen, setStudyOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const pricingTiers = [
    {
      title: "Always Free",
      price: "Free With No Ads",
      features: [
        { main: "All Radicals", sub: "Includes 216 characters" },
        { main: "Duolingo Section 1", sub: "Includes 212 words and phrases" },
        { main: "Domino Chinese Ch 1-3", sub: "Includes 244 words and phrases" },
        { main: "Unlimited Quiz Attempts", sub: "Take quizzes as many times as you like" },
      ],
    },
    {
      title: "Monthly Subscription",
      price: "$3 per Month",
      features: [
        // { main: "Includes all free content", sub: "" },
        { main: "Duolingo Sections 1 & 2", sub: "Includes 516 words and phrases" },
        { main: "Domino Chinese Courses 1-3", sub: "Includes 3,167 words and phrases" },
        { main: "Paronym Quizzes", sub: "47 quizzes for characters with the same pronunciation" },
        { main: "Parts of Speech Quizzes", sub: "21 quizzes covering all HSK 1 & 2" },
      ],
    },
    {
      title: "Annual Subscription",
      price: "$24 per Year",
      features: [
        // { main: "Includes all free content", sub: "" },
        { main: "Duolingo Sections 1 & 2", sub: "Includes 516 words and phrases" },
        { main: "Domino Chinese Courses 1-3", sub: "Includes 3,167 words and phrases" },
        { main: "Paronym Quizzes", sub: "47 quizzes for characters with the same pronunciation" },
        { main: "Parts of Speech Quizzes", sub: "21 quizzes covering all HSK 1 & 2" },
      ],
    },
  ];

  // const fonts = [
  //   { name: "Montserrat", label: "Montserrat" },
  //   { name: "Poppins", label: "Poppins" },
  //   { name: "Nunito Sans", label: "Nunito Sans" },
  //   { name: "Rubik", label: "Rubik" },
  //   { name: "Manrope", label: "Manrope" },
  //   { name: "Merriweather", label: "Merriweather" },
  //   { name: "Playfair Display", label: "Playfair Display" },
  //   { name: "Lora", label: "Lora" },
  //   { name: "IBM Plex Sans", label: "IBM Plex Sans" },
  //   { name: "Urbanist", label: "Urbanist" },
  // ];

  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1, // This ensures the content expands fully
        }}
      >
        {" "}
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            mb: 4,
          }}
        >
          <Grid item xs={12} sx={{ my: { xs: 4, sm: 6 } }}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontFamily: "Sanchez",
                fontWeight: 600,
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                lineHeight: 1.25,
                textAlign: "center",
                mx: 2,
              }}
            >
              Mandarin Chinese Flashcards
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 12,
            }}
          >
            {" "}
            <Box
              component="img"
              src={isSmallScreen ? "/images/MobileMockup.png" : "/images/deviceMockups.png"}
              alt="Screenshot of HSKCard.com"
              sx={{
                width: "80%",
                maxWidth: 800,
              }}
            />
          </Grid>
          <Grid container item>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                my: { xs: 4, sm: 8 },
              }}
            >
              <Box sx={{ maxWidth: 400, mx: "auto" }}>
                <Typography
                  variant="h3"
                  textAlign="center"
                  sx={{
                    mb: 2,
                    width: "100%",
                    textAlign: { xs: "center", md: "center" },
                    fontWeight: 600,
                    fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.75rem" },
                    color: "#4059AD",
                  }}
                >
                  Study Pages
                </Typography>
                <List sx={{ gap: 0.5, p: 0, mb: 2 }}>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#4059AD", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Categorized by Duolingo and Domino Chinese course/unit, HSK level, and parts of speech.
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#4059AD", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Displays definition, pinyin, meaning, building blocks, and example phrases.
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#4059AD", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Click to hear the audio, mark as high priority, or flag a character for review.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: { xs: 4, sm: 8 },
              }}
            >
              <Box
                component="img"
                src="/images/study_domino.png"
                alt="Study page screenshot"
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  cursor: "pointer",
                  boxShadow: 0,
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => setStudyOpen(true)}
              />
              <Dialog
                open={studyOpen}
                onClose={() => setStudyOpen(false)}
                maxWidth="md"
                fullWidth
                sx={{
                  "& .MuiPaper-root": { backgroundColor: "transparent", boxShadow: "none" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden", // Prevents scrolling outside modal
                  }}
                >
                  <Box
                    component="img"
                    src="/images/studyCallout.jpg"
                    alt="Study page screenshot"
                    sx={{
                      width: "100%",
                      maxWidth: "90vw", // Makes it responsive
                      maxHeight: "90vh",
                      objectFit: "contain",
                      touchAction: "pinch-zoom", // Enables pinch zooming on mobile
                    }}
                  />
                </Box>
              </Dialog>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                order: { xs: 2, md: 1 },
                my: { xs: 4, sm: 8 },
              }}
            >
              <Box
                component="img"
                src="/images/quiz_duo16.png"
                alt="Quiz Screenshot"
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  cursor: "pointer",
                  boxShadow: 0,
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => setQuizOpen(true)}
              />
              <Dialog
                open={quizOpen}
                onClose={() => setQuizOpen(false)}
                maxWidth="md"
                fullWidth
                sx={{
                  "& .MuiPaper-root": { backgroundColor: "transparent", boxShadow: "none" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden", // Prevents scrolling outside modal
                  }}
                >
                  <Box
                    component="img"
                    src="/images/quizCallout2.jpg"
                    alt="Quiz Screenshot"
                    sx={{
                      width: "100%",
                      maxWidth: "90vw", // Makes it responsive
                      maxHeight: "90vh",
                      objectFit: "contain",
                      touchAction: "pinch-zoom", // Enables pinch zooming on mobile
                    }}
                  />
                </Box>
              </Dialog>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mb: 3,
                order: { xs: 1, md: 2 },
                my: { xs: 4, sm: 8 },
              }}
            >
              <Box sx={{ maxWidth: 400, mx: "auto" }}>
                <Typography
                  variant="h3"
                  textAlign="center"
                  sx={{
                    mb: 2,
                    width: "100%",
                    textAlign: { xs: "center", md: "center" },
                    fontWeight: 600,
                    fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.75rem" },
                    color: "#BA7BA1",
                  }}
                >
                  Quiz Pages
                </Typography>
                <List sx={{ gap: 0.5, p: 0, mb: 2 }}>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#BA7BA1", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Front of card displays either character, pinyin, or meaning. Flip to see the meaning and hear it
                      pronounced.
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#BA7BA1", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Quizzes are limited to 15-20 questions for optimal retention.
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters sx={{ py: 0 }}>
                    <ListItemIcon sx={{ minWidth: "24px", ml: 2, mb: 3, color: "#BA7BA1", fontSize: "2rem" }}>
                      •
                    </ListItemIcon>
                    <Typography variant="body1">
                      Use keyboard mode or click/tap mode to learn the way that works best for you.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ my: { xs: 6, sm: 8 } }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <iframe
                title="Intro Video"
                src="https://fast.wistia.net/embed/iframe/59pzpwvl4a?videoFoam=true"
                allow="autoplay; fullscreen"
                allowTransparency="true"
                frameBorder="0"
                scrolling="no"
                style={{
                  width: "100%",
                  height: "450px",
                  maxWidth: "800px",
                  border: "3px solid #ccc",
                  borderRadius: "16px",
                }}
              ></iframe>
            </Box>

            <Typography
              variant="h3"
              textAlign="center"
              sx={{
                mt: { xs: 4, sm: 8 },
                mb: 6,
                width: "100%",
                fontWeight: 600,
                fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.75rem" },
                color: "#4059AD",
              }}
            >
              Choose Your Plan
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {pricingTiers.map((tier, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      px: 2,
                      py: 4,
                      backgroundColor: "white",
                      borderRadius: "12px",
                      boxShadow: 3,
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: 700, color: "#BA7BA1", mb: 3 }}>
                      {tier.title}
                    </Typography>
                    <Typography variant="h6" color="primary" textTransform="uppercase" sx={{ mb: 2, fontSize: "1rem" }}>
                      {tier.price}
                    </Typography>
                    <Divider sx={{ mb: 1, width: "80%", mx: "auto" }} />
                    <List sx={{ flexGrow: 1, textAlign: "left", mb: 3 }}>
                      {tier.features.map((feature, i) => (
                        <ListItem
                          key={i}
                          sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", py: 1 }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            <CheckIcon sx={{ color: "orange", mr: 1, pt: 0.5 }} /> {feature.main}
                          </Typography>
                          {feature.sub && (
                            <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                              {feature.sub}
                            </Typography>
                          )}
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      texttransform="none"
                      onClick={() => navigate("/register")}
                      sx={{
                        backgroundColor: "hsl(37,100%,92%)",
                        border: "1px solid",
                        color: "hsl(37,100%,30%)",
                        width: "200px",
                        mx: "auto",
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: 4, sm: 8 },
            mb: 2,
            minHeight: "400px",
            backgroundImage: 'url("/images/orange-mnt-bkgnd3.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%", // Ensures the content is centered
              // backgroundColor: "rgba(249, 249, 249, 0.7)", // Semi-transparent background for better readability
              pt: 4,
              pb: 18,
            }}
          >
            <Typography
              variant="h3"
              textAlign="center"
              sx={{
                mb: 2,
                width: "100%",
                fontWeight: 600,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                color: "#4059AD",
              }}
            >
              Newsletter
            </Typography>
            <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
              Get the latest content updates and news. No spam, ever.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                sx={{ width: 300, backgroundColor: "white" }}
              />
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "hsl(37,100%,95%)",
                  },
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
