import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import TopNavBarPublic from "./TopNavBarPublic";
import Footer from "./Footer";
import { useThemeContext } from "./ThemeContext";

const LayoutPublic = () => {
  const { dark, setDark } = useThemeContext();

  // Force light mode whenever this layout is used
  useEffect(() => {
    if (dark) {
      setDark(false);
    }
  }, [dark, setDark]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <TopNavBarPublic />
      {/* Main Content Area */}
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default LayoutPublic;
